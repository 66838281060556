<template>
  <!-- 个人中心 -->
  <div class="page-container">
    <div class="page-top-tool">
      个人中心
    </div>
    <div class="form-layout">
      <el-form :model="form" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <!-- <el-form-item label="员工编号">
          <el-input v-model="form.code" disabled size="mini" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="form.name" size="mini" placeholder="请输入网点名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="入职岗位" prop="name">
          <el-input v-model="form.name" size="mini" placeholder="请输入网点名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="所属网点" prop="area">
          <el-select v-model="form.area" size="mini" placeholder="请选择地区" style="width: 300px;">
            <el-option label="郑州" value="zhengzhou"></el-option>
            <el-option label="许昌" value="xuchang"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级领导" prop="area">
          <el-select v-model="form.area" size="mini" placeholder="请选择地区" style="width: 300px;">
            <el-option label="郑州" value="zhengzhou"></el-option>
            <el-option label="许昌" value="xuchang"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="name">
          <el-input v-model="form.name" size="mini" placeholder="请输入网点名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="name">
          <el-input v-model="form.name" size="mini" placeholder="请输入网点名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="address">
          <el-input v-model="form.address" type="textarea" :rows="2" size="mini" placeholder="请输入详细地址" style="width: 300px;"></el-input>
        </el-form-item> -->
        <el-form-item label="上传">
          <el-upload
            class="upload-demo"
            action="http://localhost:3001/hjl/upload/img"
            :on-success="handleAvatarSuccess"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="bottom-submit-btn">
        <el-button type="primary" size="mini" @click="submitForm('ruleForm')">提 交</el-button>
        <el-button size="mini" @click="resetForm('ruleForm')">重 置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCenterPage',
  data() {
    return {
      form: {
        code: '',
        name: '',
        area: '',
        address: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入网点名称', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请选择地区', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入网点地址', trigger: 'blur' }
        ]
      },
      fileList: []
    };
  },

  mounted() {
    
  },

  methods: {
    goBack() {
      this.$router.back()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            alert('submit!');
        } else {
          return false;
        }
      })
    },
    handleAvatarSuccess(res, file) {
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .form-layout {
    margin: 30px 0px;
    padding: 10px 30px;
  }

  .bottom-submit-btn {
    padding: 10px 30px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
</style>